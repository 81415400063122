export default defineNuxtRouteMiddleware((to) => {
    const user = useSupabaseUser()
    const { $i18n } = useNuxtApp()

    if (!user.value) {
        return navigateTo(`/${$i18n.locale.value}/login`)
    }

    if (to.path === `/${$i18n.locale.value}` || to.path === `/${$i18n.locale.value}/`) {
        return navigateTo(`/${$i18n.locale.value}/app`)
    }
})
